import { useTranslation } from 'react-i18next'

export const SUCCESS_STATUS_ID = 'success'
export const FAILED_STATUS_ID = 'failed'

export const useCutStatuses = () => {
    const { t } = useTranslation()

    return [
        { id: SUCCESS_STATUS_ID, name: t('dictionary.successful') },
        { id: FAILED_STATUS_ID, name: t('dictionary.failed') },
    ]
}
