import { useMemo, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { PrinterIcon } from '@heroicons/react/outline'

import { listReducer } from '../helpers'
import PageLayout from '../components/PageLayout'
import { Loader } from '../components/ui'

import type {
    ListFilterAction,
    StatisticsFilterState,
    StatisticsFilterAction,
} from '../types'
import Statistics from '../components/Statistics'
import {
    useStatisticsCompareQuery,
    useStatisticsQuery,
} from '../api/endpoints/statistics'
import { DatePicker } from 'components/ui/DatePickers'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { usePolicy } from 'hooks/usePolicy'
import { useUserByIdQuery } from 'api'
import { UseQueryResult } from 'react-query'
import {
    ResponseData,
    ResponseError,
    StatisticsCompareResponse,
    StatisticsResponse,
} from 'api/types'
import { useUserRoles } from 'hooks'

const filterReducer = (
    state: StatisticsFilterState,
    action: StatisticsFilterAction
) => {
    if (action.type === 'setFilterQuery') {
        return {
            ...state,
            search_global: action.payload,
        }
    }

    if (action.type === 'setFilterDate') {
        return {
            ...state,
            from: action.payload.from,
            to: action.payload.to,
        }
    }

    if (action.type === 'setFilterFrom') {
        return {
            ...state,
            from: action.payload,
        }
    }

    if (action.type === 'setFilterTo') {
        return {
            ...state,
            to: action.payload,
        }
    }

    if (action.type === 'resetFilters') {
        return {
            ...state,
            search_global: '',
            from: undefined,
            to: undefined,
            page: 1,
        }
    }

    return listReducer(
        state,
        action as ListFilterAction
    ) as StatisticsFilterState
}

const lowerLimitDate = moment(new Date()).subtract(1, 'year').toDate()
const upperLimitDate = new Date()

const defaultFrom = moment(new Date())
    .subtract(1, 'month')
    .add(1, 'day')
    .toDate()
const defaultTo = upperLimitDate

export default function StatisticsFullView() {
    const { t } = useTranslation()

    const policy = usePolicy()
    const { supervisor_id, user_id } = useParams()

    const [filters, dispatchFilterAction] = useReducer(filterReducer, {
        search_global: '',
        from: defaultFrom,
        to: defaultTo,
    })

    const apiFilters = useMemo(() => {
        return {
            ...filters,
            from: moment(filters.from || defaultFrom).format('YYYY-MM-DD'),
            to: moment(filters.to || defaultTo).format('YYYY-MM-DD'),
            search_global: filters.search_global || undefined,
            user_id: user_id,
            superior_id: supervisor_id,
        }
    }, [filters, supervisor_id, user_id])

    const statisticsQuery = useStatisticsQuery(apiFilters)

    return (
        <>
            {user_id || supervisor_id ? (
                <SelectedUserStatistics
                    title={t('statistics.title')}
                    filters={filters}
                    apiFilters={apiFilters}
                    dispatchFilterAction={dispatchFilterAction}
                    statisticsQuery={statisticsQuery}
                    isUser={!!user_id || policy.isUser()}
                    isSupervisor={!!supervisor_id || policy.isSupervisor()}
                    userId={user_id || supervisor_id || ''}
                />
            ) : (
                <StatisticLayoutPage
                    title={t('statistics.title')}
                    filters={filters}
                    dispatchFilterAction={dispatchFilterAction}
                    statisticsQuery={statisticsQuery}
                    isUser={!!user_id || policy.isUser()}
                    isSupervisor={!!supervisor_id || policy.isSupervisor()}
                />
            )}
        </>
    )
}

interface StatisticLayoutPageProps {
    title: string
    statisticsQuery: UseQueryResult<
        ResponseData<StatisticsResponse>,
        ResponseError
    >
    statisticsCompareQuery?: UseQueryResult<
        ResponseData<StatisticsCompareResponse>,
        ResponseError
    >
    filters: StatisticsFilterState
    dispatchFilterAction: (v: StatisticsFilterAction) => void
    isUser: boolean
    isSupervisor: boolean
}

function SelectedUserStatistics({
    ...props
}: { userId: string; apiFilters: any } & StatisticLayoutPageProps) {
    const userQuery = useUserByIdQuery(props.userId)
    const roles = useUserRoles()

    if (userQuery.isLoading) {
        return <Loader />
    }

    if (String(userQuery.data?.data.role?.id) === roles.supervisor.id) {
        return (
            <SupervisorStatistics
                {...props}
                title={`${props.title} ${userQuery.data?.data.name || ''}`}
            />
        )
    }

    return (
        <StatisticLayoutPage
            {...props}
            title={`${props.title} ${userQuery.data?.data.name || ''}`}
        />
    )
}

function SupervisorStatistics(
    props: { isSupervisor: boolean; apiFilters: any } & StatisticLayoutPageProps
) {
    const statisticsCompareQuery = useStatisticsCompareQuery(props.apiFilters)

    return (
        <StatisticLayoutPage
            statisticsCompareQuery={statisticsCompareQuery}
            {...props}
        />
    )
}

function StatisticLayoutPage({
    title,
    statisticsQuery,
    statisticsCompareQuery,
    filters,
    dispatchFilterAction,
    isUser,
    isSupervisor,
}: StatisticLayoutPageProps) {
    const { t } = useTranslation()

    return (
        <PageLayout
            title={title}
            actions={
                <>
                    <span className="w-32">
                        <DatePicker
                            disabledBefore={lowerLimitDate}
                            disabledAfter={filters.to || upperLimitDate}
                            startMonth={lowerLimitDate}
                            endMonth={filters.to || upperLimitDate}
                            value={filters.from}
                            onChange={(value) =>
                                value &&
                                dispatchFilterAction({
                                    type: 'setFilterDate',
                                    payload: {
                                        from: value,
                                        to: filters.to,
                                    },
                                })
                            }
                            // captionLayout='dropdown-buttons'
                        />
                    </span>
                    <span className="w-32 ml-2 mr-6">
                        <DatePicker
                            disabledBefore={filters.from || lowerLimitDate}
                            disabledAfter={upperLimitDate}
                            startMonth={filters.from || lowerLimitDate}
                            endMonth={upperLimitDate}
                            value={filters.to}
                            onChange={(value) =>
                                value &&
                                dispatchFilterAction({
                                    type: 'setFilterDate',
                                    payload: {
                                        from: filters.from,
                                        to: value,
                                    },
                                })
                            }
                            // captionLayout='dropdown-buttons'
                        />
                    </span>
                    <span className="pl-6 border-l border-white">
                        <div className="relative inline-flex">
                            <button
                                type="button"
                                onClick={() => {
                                    window.dispatchEvent(
                                        new Event('beforeprint', {
                                            bubbles: true,
                                        })
                                    )
                                    setTimeout(() => window.print(), 100) // hack to allow chart-js rerender itself before printing
                                }}
                                className="relative flex items-center px-4 py-2 border border-gray-600 shadow-sm leading-5 text-sm font-medium rounded-md text-white bg-gray-800"
                            >
                                {t('action.print')}
                                <PrinterIcon
                                    className="-ml-0.5 md:ml-2 h-4 w-4 text-gray-300"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                    </span>
                </>
            }
        >
            <>
                {statisticsQuery.isLoading && <Loader />}
                {statisticsQuery.isError && (
                    <div className="text-red-600">
                        {statisticsQuery.error.message}
                    </div>
                )}
                {statisticsQuery.isSuccess && (
                    <Statistics
                        statisticsQuery={statisticsQuery}
                        statisticsCompareQuery={statisticsCompareQuery}
                        filters={filters}
                        isUser={isUser}
                        isSupervisor={isSupervisor}
                    />
                )}
            </>
        </PageLayout>
    )
}
