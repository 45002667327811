const en = {
    'sidebar.users': 'Users',
    'sidebar.clients': 'Clients',
    'sidebar.plotters': 'Plotters',
    'sidebar.brand': 'Producents',
    'sidebar.patterns': 'Patterns',
    'sidebar.supervisors': ' Supervisors',
    'sidebar.stats': 'Statistics',
    'sidebar.report': 'Detailed report',
    'sidebar.qr': 'Codes generator',
    'sidebar.device': 'Devices',
    'auth.logout': 'Sign out',
    'auth.email': 'E-mail',
    'auth.password': 'Password',
    'auth.repeat_password': 'Repeat password',
    'auth.remind': 'Remind password',
    'auth.login': 'Sign in',
    'auth.title': 'Sign in to Hammer Glass service',
    'auth.remind.title': 'The password is reminded via an activation link.',
    'auth.remind.subtitle': 'Enter your account email address.',
    'auth.remind.send': 'Send a password reminder link',
    'auth.remind.success.title': 'The link has been sent',
    'auth.remind.success.subtitle':
        'Go to your mailbox and confirm the password change by clicking on the link received',
    'auth.reset.title': 'New password',
    'auth.reset.save': 'Save',
    'plotter.list.title': 'Cutting plotter list',
    'user.list.title': 'Users list',
    'dictionary.active': 'Active',
    'dictionary.inactive': 'Inactive',
    'dictionary.user': 'User',
    'dictionary.user_short': 'User',
    'dictionary.supervisor': 'Supervisor',
    'dictionary.supervisor_short': 'Supervisor',
    'dictionary.admin': 'Administrator',
    'dictionary.admin_short': 'Admin',
    'dictionary.admin_description':
        'Has access to everything in the system. Can add, edit, save and delete.',
    'dictionary.supervisor_description':
        'Region supervisor. Can activate devices, add retail users and assign plotters.',
    'dictionary.user_description': 'Retail user',
    'dictionary.has': 'Has',
    'dictionary.hasnot': 'Has not',
    'dictionary.all': 'All',
    'dictionary.successful': 'Successful',
    'dictionary.failed': 'Failed',
    'filters.role': 'Role',
    'filters.status': 'Status',
    'filters.country': 'Country',
    'filters.superior': 'Supervisor',
    'filters.region': 'Region',
    'filters.city': 'City',
    'filters.device': 'Device',
    'filters.plotter': 'Plotter',
    'filters.film': 'Film',
    'filters.size': 'Size',
    'filters.pattern': 'Pattern',
    'filters.patterns': 'Patterns',
    'filters.brand': 'Brand',
    'filters.type': 'Type',
    'filters.success': 'Status',
    'action.user.create': 'Add user',
    'action.plotter.create': 'Add plotter',
    'action.brand.create': 'Add producent',
    'action.device.create': 'Add device',
    'action.pattern.create': 'Add pattern',
    'action.filters': 'Filters',
    'action.export': 'Export XLS',
    'action.search': 'Search',
    'action.delete': 'Delete',
    'action.print': 'Print',
    'list.user.id': 'Id',
    'list.user.name': 'Name',
    'list.user.email': 'E-mail',
    'list.user.country': 'Country',
    'list.user.role': 'Role',
    'list.user.status': 'Status',
    'list.user.empty_list': 'There are no users.',
    'list.user.empty_search': 'No search results found.',
    'list.user.edit': 'Edit',
    'list.user.send_password': 'Send password',
    'list.user.stats': 'Statistics',
    'list.user.deactivate': 'Deactivate',
    'list.user.remove': 'Remove',
    'form.user.new.title': 'Add new user',
    'form.user.field.first_name': 'First name',
    'form.user.field.last_name': 'Last name',
    'form.user.field.phone': 'Phone',
    'form.user.field.email': 'E-mail',
    'form.user.field.name': 'Name',
    'form.user.field.supervisor': 'Supervisor',
    'form.user.field.language': 'Language',
    'form.user.field.role': 'Role',
    'form.user.field.country': 'Country',
    'form.user.field.region': 'Region',
    'form.user.field.city': 'City',
    'form.user.title.plotters': 'Plotters',
    'form.user.tabs.data': 'Data',
    'form.user.tabs.features': 'Features',
    'form.user.tabs.users': 'Users',
    'form.user.add_plotter': 'Add plotter',
    'form.plotter.title': 'Add plotter',
    'form.plotter.edit_title': 'Edit plotter',
    'form.plotter.field.name': 'Name',
    'form.plotter.field.description': 'Description',
    'form.plotter.field.serial': 'Serial number',
    'form.brand.title': 'Add producent',
    'form.brand.edit_title': 'Edit producent',
    'form.brand.field.name': 'Name',
    'form.brand.field.order': 'Order',
    'form.device.title': 'Add new device',
    'form.device.edit_title': 'Edit device',
    'form.device.field.name': 'Name',
    'form.device.field.type': 'Type',
    'form.device.field.brand': 'Producent',
    'form.device.field.date': 'Release date',
    'form.pattern.title': 'Add pattern',
    'form.pattern.edit_title': 'Edit pattern',
    'form.pattern.field.name': 'Name',
    'form.pattern.field.size': 'Size',
    'form.pattern.field.png': 'PNG file',
    'form.pattern.field.plt': 'Pattern file',
    'form.export.title': 'Export XLS',
    'form.export.data_label': 'Scope of the data',
    'form.export.extra_label': 'Choose additional data for export',
    'form.export.field.supervisor': 'Supervisor',
    'form.export.field.country': 'Country',
    'form.export.field.city': 'City',
    'form.export.field.device': 'Device',
    'form.export.field.film': 'Film',
    'form.export.field.type': 'Type',
    'form.export.field.pattern': 'Pattern',
    'form.export.field.successful': 'Status',
    'form.export.field.wifi': 'Wifi data',
    'form.export.field.language': 'Language',
    'form.export.field.theme': 'Theme',
    'form.export.field.ip': 'Gate IP',
    'form.export.field.from': 'From',
    'form.export.field.to': 'To',
    'form.qr.title': 'Codes generator',
    'form.qr.subtitle': 'New QR codes generator',
    'form.qr.field.size': 'Size',
    'form.qr.field.type': 'Film type',
    'form.qr.field.count': 'Codes quantity',
    'form.cancel': 'Cancel',
    'form.save': 'Save',
    'form.add': 'Add',
    'form.export': 'Download',
    'form.confirm': 'Confirm',
    'form.generate': 'Generate new codes',
    'list.plotter.id': 'Id',
    'list.plotter.name': 'Plotter name',
    'list.plotter.owner': 'Owner',
    'list.plotter.description': 'Description',
    'list.plotter.serial': 'Serial number',
    'list.plotter.status': 'Status',
    'list.plotter.empty_list': 'There are no plotters',
    'list.plotter.empty_search': 'No search results found.',
    'list.plotter.deactivate': 'Deactivate',
    'list.plotter.delete': 'Delete',
    'list.plotter.lock': 'Lock',
    'list.plotter.assign': 'Assign to user',
    'list.plotter.edit': 'Edit',
    'list.plotter.detach': 'Detach',
    'list.report.title': 'Detailed raport',
    'list.report.data': 'Date',
    'list.report.client': 'Client',
    'list.report.superior': 'Supervisor',
    'list.report.cutter': 'Plotter',
    'list.report.country': 'Country',
    'list.report.region': 'Region',
    'list.report.city': 'City',
    'list.report.device': 'Device',
    'list.report.foil_type': 'Film and type',
    'list.report.pattern': 'Pattern',
    'list.report.empty_list': 'There are no entries.',
    'list.report.empty_search': 'No search results found.',
    'list.brand.title': 'Producents',
    'list.brand.id': 'Id',
    'list.brand.logo': 'Logo',
    'list.brand.name': 'Producent name',
    'list.brand.order': 'Order',
    'list.brand.devices': 'Devices',
    'list.brand.devices_count': 'Devices',
    'list.brand.empty_list': 'There are no producents.',
    'list.brand.empty_search': 'No search results found.',
    'list.brand.edit': 'Edit',
    'list.brand.delete': 'Delete',
    'list.device.title': 'Devices list',
    'list.device.id': 'Id',
    'list.device.image': 'Preview',
    'list.device.name': 'Device',
    'list.device.type': 'Type',
    'list.device.date': 'Release date',
    'list.device.patterns': 'Patterns',
    'list.device.empty_list': 'There are no devices.',
    'list.device.empty_search': 'No search results found.',
    'list.device.edit': 'Edit',
    'list.device.delete': 'Delete',
    'list.pattern.title': 'Patterns',
    'list.pattern.id': 'Id',
    'list.pattern.image': 'Preview',
    'list.pattern.name': 'Name',
    'list.pattern.size': 'Size',
    'list.pattern.device': 'Device',
    'list.pattern.brand': 'Producent',
    'list.pattern.type': 'Type',
    'list.pattern.date': 'Creation date',
    'list.pattern.empty_list': 'There are no patterns.',
    'list.pattern.empty_search': 'No search results found.',
    'list.pattern.edit': 'Edit',
    'list.pattern.delete': 'Delete',
    'list.empty_list': 'Empty list',
    'footer.message': 'Showing {{from}} to {{to}} of {{total}} results',
    'info.user.updated': 'User has been updated',
    'info.user.saved': 'User has been created',
    'info.user.link_send': 'Link has been sent',
    'info.plotter.saved': 'Plotter has been saved',
    'info.plotter.updated': 'Plotter has been updated',
    'info.plotter.deleted': 'Plotter has been deleted',
    'info.user.deleted': 'User has been deleted',
    'info.brand.saved': 'Producent has been saved',
    'info.brand.updated': 'Producent has been updated',
    'info.brand.deleted': 'Producent has been deleted',
    'info.device.saved': 'Device has been saved',
    'info.device.updated': 'Device has been updated',
    'info.device.deleted': 'Device has been deleted',
    'info.pattern.saved': 'Pattern has been saved',
    'info.pattern.updated': 'Pattern has been updated',
    'info.pattern.deleted': 'Pattern has been deleted',
    'info.qr.saved': 'New codes has been generated',
    'info.export.saved': 'Data has been downloaded',
    'info.export.failed': 'Data download failed',
    'deactivate.user.title': 'Disabling user',
    'deactivate.user.description':
        'Are you sure you want to disable this user?',
    'deactivate.user.action': 'Disable',
    'delete.user.title': 'Delete user',
    'delete.user.description': 'Are you sure you want to delete this user?',
    'delete.user.action': 'Delete',
    'deactivate.plotter.title': 'Disabling plotter',
    'deactivate.plotter.description':
        'Are you sure you want to disable this plotter?',
    'deactivate.plotter.action': 'Disable',
    'assign.plotter.title': 'Add plotter',
    'assign.plotter.description':
        'You can add one or multiple plotters. When adding multiple plotters, use a comma as a serial number separator.',
    'assign.plotter.label': 'Name / Serial number',
    'detach.plotter.title': 'Detach plotter',
    'detach.plotter.description':
        'Are you sure you want to remove this plotter from user?.',
    'detach.plotter.action': 'Detach',
    'delete.plotter.title': 'Delete plotter',
    'delete.plotter.description':
        'Are you sure you want to delete this plotter?.',
    'delete.plotter.action': 'Delete',
    'delete.brand.title': 'Delete producent',
    'delete.brand.description':
        'Deleting producent is possible if there is no assigned devices to it. Do you want to delete it?',
    'delete.device.title': 'Delete device',
    'delete.device.description': 'Are you sure you want to delete this device?',
    'delete.pattern.title': 'Delete pattern',
    'delete.pattern.description':
        'Are you sure you want to delete this pattern?',
    'attach.user.title': 'Add user',
    'attach.user.description':
        'Find existing users and assign them to supervisor.',
    'attach.user.label': 'User name',
    'assign.user.title': 'Assign user',
    'assign.user.description': 'Find existing user and assign him to plotter.',
    'statistics.title': 'Statistics',
    'statistics.bar_chart.title': 'Clients usage',
    'statistics.series_chart.title': 'Film types',
    'statistics.summary.title': 'Summary',
    'statistics.data_link': 'Detailed data',
    'statistics.totals.protection_films_count': 'Used films',
    'statistics.totals.cutters_count': 'Active plotters',
    'statistics.totals.clients_count': 'Clients',
    'statistics.pie_chart.title': 'Device types',
    'statistics.cutters_chart.title': 'Active plotters',
    'statistics.brands_chart.title': 'Top 10 Producents',
    'statistics.devices_chart.title': 'Top 10 Devices',
    'protection_films_sizes.A': 'A - Small',
    'protection_films_sizes.B': 'B - Medium',
    'protection_films_sizes.C': 'C - Large',
    'protection_films_types.1': 'Cristal shield',
    'protection_films_types.2': 'UV Cristal shield',
    'protection_films_types.3': 'Matte Finish',
    'protection_films_types.4': 'UV Matte Finish',
    'protection_films_types.5': 'Private view Matt',
    'protection_films_types.6': 'Unknown 1',
    'protection_films_types.7': 'Unknown 2',
    'protection_films_types.8': 'Unknown 3',
    'device_type.phone': 'Phone',
    'device_type.tablet': 'Tablet',
    'device_type.watch': 'Watch',
    'device_type.other': 'Other',
    'qrgenerator.summary.for_use': 'Films left',
    'qrgenerator.summary.used': 'Used films',
    'qrgenerator.summary.total_count': 'Films total',
    'qrgenerator.summary.title': 'Summary',
    'cutters_series.actual_cuters': 'Actual',
    'cutters_series.compared_cuters': 'From {{from}} to {{to}}',
    'country.PL': 'Poland',
    'country.DE': 'Germany',
    'country.CZ': 'Czech Republic',
    'country.BF': 'Burkina Faso',
    'country.SK': 'Slovakia',
    'country.IL': 'Israel',
    'bread.user_list': 'User list',
    'bread.device': 'Devices',
    'bread.pattern': 'Patterns',
    'day.monday': 'Mon',
    'day.tuesday': 'Tue',
    'day.wednesday': 'Wed',
    'day.thursday': 'Thu',
    'day.friday': 'Fri',
    'day.saturday': 'Sat',
    'day.sunday': 'Sun',
    'dropzone.text_bold': 'Choose file',
    'dropzone.text_normal': 'or drag and drop it',
    'dropzone.limit': ' up to 2MB',
}

export default en
