import { useEffect, useMemo } from 'react'
import { useField } from 'formik'
import { DropdownItem } from 'types'
import { ResponseList } from 'api/types'
import {
    useDropdownIsOpen,
    useDropdownSearch,
    useInfiniteDropdown,
    useMultiDropdown,
} from 'components/ui/Dropdowns/hooks'
import DropdownCombobox from 'components/ui/Dropdowns/DropdownBase/DropdownCombobox'
import DropdownComboboxInput from 'components/ui/Dropdowns/DropdownBase/DropdownComboboxInput'
import DropdownComboboxButton from 'components/ui/Dropdowns/DropdownBase/DropdownComboboxButton'
import DropdownComboboxOptions from 'components/ui/Dropdowns/DropdownBase/DropdownComboboxOptions'
import InfiniteDropdownOptions from 'components/ui/Dropdowns/InfiniteDropdownOptions'
import { ComboboxDropdownItem } from 'components/ui'

interface InfiniteDropdownWithSearchFieldProps<T> {
    name: string
    disabled?: boolean
    mapFn: (v: T) => DropdownItem
    queryFn: (filters?: {}) => Promise<ResponseList<T[]>>
    queryFilters?: {}
}

function InfiniteMultiDropdownWithSearchField<T>(
    props: InfiniteDropdownWithSearchFieldProps<T>
) {
    const { name, disabled, queryFilters, queryFn, mapFn } = props
    const [field, fieldMeta, helpers] = useField<DropdownItem[]>(name)

    const { open, setIsOpen, comboboxRef } = useDropdownIsOpen()
    const { searchPhrase, setSearchPhrase, onClose } = useDropdownSearch()
    const { onChange } = useMultiDropdown(helpers.setValue, field.value)

    const { queryResult, results, observerRef } = useInfiniteDropdown({
        name,
        open,
        searchPhrase,
        queryFn,
        queryFilters: {
            ...queryFilters,
            search_global: searchPhrase || undefined,
        },
    })

    useEffect(() => {
        if (!open) {
            onClose()
        }
    }, [open, onClose])

    const items = useMemo(() => results?.map(mapFn) || [], [results, mapFn])

    return (
        <DropdownCombobox
            value={field.value}
            disabled={disabled}
            onChange={onChange}
            ref={comboboxRef}
        >
            <DropdownComboboxInput
                open={open}
                onChange={setSearchPhrase}
                displayValue={field.value.map(({ name }) => name).join(',')}
                onBlur={(e: any) => {
                    if (e.relatedTarget === null) {
                        setIsOpen(false)
                    }
                }}
                onFocus={() => {
                    setIsOpen(true)
                }}
                disabled={disabled}
                hasError={!!fieldMeta.error}
            />
            <DropdownComboboxButton
                disabled={disabled}
                open={open}
                onClick={() => !open && setIsOpen(true)}
                hasError={!!fieldMeta.error}
            />
            <DropdownComboboxOptions open={open}>
                <InfiniteDropdownOptions
                    isEmpty={!results?.length}
                    isLoading={queryResult.isLoading}
                    ref={observerRef}
                    hasNextPage={!!queryResult.hasNextPage}
                >
                    {items.map((item) => {
                        const selected = !!field.value.find(
                            ({ id }) => id === item.id
                        )

                        return (
                            <ComboboxDropdownItem
                                key={item.id}
                                item={item}
                                selected={selected}
                            />
                        )
                    })}
                </InfiniteDropdownOptions>
            </DropdownComboboxOptions>
        </DropdownCombobox>
    )
}

export default InfiniteMultiDropdownWithSearchField
